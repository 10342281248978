import * as React from 'react';

export const Multicity = React.memo(
	({ size = 16, color = '#000', ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				{...rest}
			>
				<path
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
					d="M1 8.5h2.5"
				></path>
				<path stroke={color} d="M6 8.5h2.5"></path>
				<circle cx="7.301" cy="8.5" r="1.5" fill={color}></circle>
				<path
					stroke={color}
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.25"
					d="M11 8.507h4m0 0L13 6m2 2.507L13 11"
				></path>
			</svg>
		);
	}
);

Multicity.displayName = 'Multicity';
