const streamEvent = {
	NONE: 'none',
	PING: 'ping',
	LOADING: 'loading',
	COLD: 'cold_cache',
	WARM: 'warm_cache',
	HOT: 'hot_cache',
	INPROGRESS: 'recommendation_inprogress',
	END: 'recommendation_completed',
	FAILED: 'failed',
};

const cardLoadingState = {
	NO_LOADER: 'none',
	HALF_LOADER: 'partial',
	FULL_LOADER: 'full',
};

export const tripConstants = {
	streamEvent,
	cardLoadingState,
};

export const adminSelectionType = {
	ALL: 'all',
	LEG: 'leg',
};

export const legConstants = {
	FLIGHT: 'flight',
	HOTEL: 'hotel',
	ROOM: 'room',
};
